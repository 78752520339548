import { useCallback } from "react";
import isEmpty from "lodash/isEmpty";

import omit from "lodash/omit";
import toast from "utils/toast";
import { ApiPageViewAdditionalConfig, ApiPageViews } from "types/apiTypes";
import { RecordItem } from "types/common";
import { TOAST_TYPE, ViewOption } from "utils/constants";

import { TableViewConfig, ViewColumn } from "types/baTypes";
import { getViewApiFieldsFromPageView, getViewColumnApiFieldsFromViewColumn } from "utils/apiUtils";
import useAddRecord from "./useAddRecord";
import useRemoveRecord from "./useRemoveRecord";
import useUpdateRecord from "./useUpdateRecord";
import useRemoveMultipleRecords from "./useRemoveMultipleRecords";

export type SavePageViewInput = {
  newViewConfig: Partial<TableViewConfig>;
  newAdditionalConfig: ApiPageViewAdditionalConfig;
  fullPageView?: TableViewConfig;
  onSuccess?: () => void;
};

export type AddViewInput = {
  viewType: ViewOption;
  pageId: string;
  additionalInput?: RecordItem;
  additionalPageViewInput?: Partial<ApiPageViews>;
  onSuccess?: (pageViewId?: string) => void;
};

export type DuplicatePageViewInput = {
  pageId: string;
  additionalViewConfigInput: Partial<TableViewConfig>;
  additionalPageViewConfigInput: Partial<ApiPageViews>;
  fullPageView?: TableViewConfig;
  onSuccess?: (pageViewId?: string) => void;
};

export type AddViewColumnInput = {
  pageViewId: string;
  columnId: string;
  additionalProps?: RecordItem;
  onSuccess?: (props?: { columnId?: string; pageViewId?: string }) => void;
};

export type SaveViewColumnInput = {
  newConfig: ViewColumn;
  pageViewColumnId: string;
  onSuccess?: () => void;
};

export type SaveMultipleViewColumnInput = {
  newConfigs: {
    config: ViewColumn;
    pageViewColumnId: string;
  }[];
  onSuccess?: () => void;
};

export type AddMultipleViewColumnInput = {
  newConfigs: {
    pageViewId: string;
    columnId: string;
    additionalProps?: RecordItem;
  }[];
  onSuccess?: (props?: { columnId?: string; pageViewId?: string }[]) => void;
};

// Manages update/delete for `ui_views` that are linked to pages
const useUpsertDeletePageViewConfig = () => {
  const { updateRecordAsync } = useUpdateRecord();
  const { removeRecordAsync } = useRemoveRecord();
  const { removeMultiRecordsAsync } = useRemoveMultipleRecords();
  const { addRecordAsync } = useAddRecord();

  const handleSaveView = useCallback(
    async ({ newViewConfig, newAdditionalConfig, fullPageView, onSuccess }: SavePageViewInput) => {
      if (!fullPageView?.id) {
        return;
      }
      const updatedViewConfig = getViewApiFieldsFromPageView(newViewConfig);
      const updatedAddlConfig = {
        ...fullPageView.additionalConfig,
        ...newAdditionalConfig
      };
      const { error } = await updateRecordAsync({
        tableName: "ui_views",
        input: {
          ...updatedViewConfig,
          id: fullPageView?.viewId,
          additional_config: updatedAddlConfig
        }
      });
      if (error) {
        toast.error("Error updating column view config");
        return;
      }
      onSuccess?.();
    },
    [updateRecordAsync]
  );

  const handleSavePageView = useCallback(
    async ({
      input,
      onSuccess
    }: {
      input: {
        id: string;
        is_custom?: boolean;
        default_column_section_id?: string;
      };
      onSuccess?: () => void;
    }) => {
      if (!input?.id) {
        return;
      }

      const { error } = await updateRecordAsync({
        tableName: "ui_pages_views",
        input
      });
      if (error) {
        toast.error("Error updating page view config");
        return;
      }
      onSuccess?.();
    },
    [updateRecordAsync]
  );

  // Saves to ui_pages_views_columns
  const handleSaveViewColumnProp = useCallback(
    async ({ newConfig, pageViewColumnId, onSuccess }: SaveViewColumnInput) => {
      const convertedConfig = getViewColumnApiFieldsFromViewColumn(newConfig);

      const { error } = await updateRecordAsync({
        tableName: "ui_pages_views_columns",
        input: {
          ...convertedConfig,
          id: pageViewColumnId
        }
      });
      if (error) {
        toast.error("Error updating column view config");
        return;
      }
      onSuccess?.();
    },
    [updateRecordAsync]
  );

  // Saves multiple values to ui_pages_views_columns
  const handleUpdateMultiViewColumnsProps = useCallback(
    async ({ newConfigs, onSuccess }: SaveMultipleViewColumnInput) => {
      const responses = await Promise.all(
        newConfigs.map(({ config, pageViewColumnId }) => {
          const convertedConfig = getViewColumnApiFieldsFromViewColumn(config);
          const input = {
            ...convertedConfig,
            id: pageViewColumnId
          };
          return updateRecordAsync({
            tableName: "ui_pages_views_columns",
            input: input
          });
        })
      );

      const errorResponses = responses.filter(({ error }) => !!error);
      if (errorResponses?.length) {
        toast.error("Error updating columns view config");
        return;
      }
      onSuccess?.();
    },
    [updateRecordAsync]
  );

  // Also update ui_pages_views to link the view to the page
  const handleAddNewPageView = useCallback(
    async ({ viewType, pageId, additionalInput, onSuccess, additionalPageViewInput }: AddViewInput) => {
      const { data, error } = await addRecordAsync({
        tableName: "ui_views",
        input: {
          view_type: viewType,
          ...additionalInput
        }
      });
      if (error) {
        toast.error("Error adding new view");
        return null;
      }
      if (data?.[0]?.id) {
        const { data: newPageView } = await addRecordAsync({
          tableName: "ui_pages_views",
          input: {
            pages_id: pageId,
            views_id: data[0].id,
            ...additionalPageViewInput
          }
        });
        onSuccess?.(newPageView?.[0]?.id);
      }
      return data?.[0];
    },
    [addRecordAsync]
  );

  const handleDeleteView = useCallback(
    async ({
      viewId,
      onSuccess,
      isChildPage,
      pageViewId
    }: {
      viewId: string;
      onSuccess?: () => void;
      isChildPage?: boolean;
      pageViewId?: string;
    }) => {
      if (isChildPage) {
        if (pageViewId) {
          // We only unlink the view from the page and don't delete it
          await removeRecordAsync({
            tableName: "ui_pages_views",
            id: pageViewId
          });
        }
        return;
      }
      const { error } = await removeRecordAsync({
        tableName: "ui_views",
        id: viewId
      });
      if (error) {
        toast.error("Error adding new view");
        return;
      }
      onSuccess?.();
    },
    [removeRecordAsync]
  );

  const deleteViewColumn = useCallback(
    async ({ pageViewColumnId, onSuccess }: { pageViewColumnId: string; onSuccess?: () => void }) => {
      if (!pageViewColumnId) {
        return;
      }
      await removeRecordAsync({
        tableName: "ui_pages_views_columns",
        id: pageViewColumnId
      });
      onSuccess?.();
    },
    [removeRecordAsync]
  );

  const deleteMultipleViewColumns = useCallback(
    async ({ pageViewColumnIds, onSuccess }: { pageViewColumnIds: string[]; onSuccess?: () => void }) => {
      if (!pageViewColumnIds.length) {
        return;
      }
      await removeMultiRecordsAsync({
        tableName: "ui_pages_views_columns",
        input: pageViewColumnIds.map((id) => ({ id, tableName: "ui_pages_views_columns" }))
      });
      onSuccess?.();
    },
    [removeMultiRecordsAsync]
  );

  const addColumnToPageView = useCallback(
    async ({ pageViewId, columnId, additionalProps, onSuccess }: AddViewColumnInput) => {
      if (!pageViewId || !columnId) {
        return;
      }
      const convertedConfig = !isEmpty(additionalProps) ? getViewColumnApiFieldsFromViewColumn(additionalProps) : {};
      const { data, error } = await addRecordAsync({
        tableName: "ui_pages_views_columns",
        input: {
          pages_views_id: pageViewId,
          columns_id: columnId,
          ...convertedConfig
        }
      });
      if (error) {
        toast.error("Error adding column to view");
        return null;
      }
      onSuccess?.({ columnId, pageViewId });
      return data?.[0];
    },
    [addRecordAsync]
  );

  const handleAddMultipleColumnToPageView = useCallback(
    async ({ newConfigs, onSuccess }: AddMultipleViewColumnInput) => {
      const responses = await Promise.all(
        newConfigs.map(({ pageViewId, columnId, additionalProps }) => {
          const convertedConfig = !isEmpty(additionalProps)
            ? getViewColumnApiFieldsFromViewColumn(additionalProps)
            : {};
          const input = {
            pages_views_id: pageViewId,
            columns_id: columnId,
            ...convertedConfig
          };
          return addRecordAsync({
            tableName: "ui_pages_views_columns",
            input: input
          });
        })
      );

      const errorResponses = responses.filter(({ error }) => !!error);
      if (errorResponses?.length) {
        toast.error("Error adding columns to view");
        return;
      }
      onSuccess?.(newConfigs);
      return responses.map(({ data }) => data?.[0]);
    },
    [addRecordAsync]
  );

  const handleDuplicatePageView = useCallback(
    async ({
      pageId,
      additionalViewConfigInput,
      additionalPageViewConfigInput,
      fullPageView,
      onSuccess
    }: DuplicatePageViewInput) => {
      const toastId = toast.success("Duplicating page view...", {
        autoClose: false
      });
      const viewConfigInput = getViewApiFieldsFromPageView({
        ...fullPageView,
        ...additionalViewConfigInput
      });

      const { data, error } = await addRecordAsync({
        tableName: "ui_views",
        input: omit(viewConfigInput, ["id"])
      });
      if (error) {
        toast.update(toastId, "Error adding new view", {
          type: TOAST_TYPE.ERROR,
          autoClose: 2000
        });
        return null;
      }

      if (data?.[0]?.id) {
        const { data: newPageView } = await addRecordAsync({
          tableName: "ui_pages_views",
          input: {
            pages_id: pageId,
            views_id: data[0].id,
            ...{
              is_custom: !!fullPageView?.isPageViewCustom,
              ...additionalPageViewConfigInput
            }
          }
        });

        if (newPageView?.[0]?.id) {
          toast.update(toastId, "Duplicating page view columns...", {
            type: TOAST_TYPE.SUCCESS,
            autoClose: false
          });
          const columns = fullPageView?.columns || [];

          const columnResponses = await Promise.all(
            columns.map((col) => {
              const columnInput = {
                columns_id: col.columnsId,
                pages_views_id: newPageView[0].id,
                ...omit(getViewColumnApiFieldsFromViewColumn(col), ["id"])
              };
              return addRecordAsync({
                tableName: "ui_pages_views_columns",
                input: columnInput
              });
            })
          );
          const columnErr = columnResponses.some(({ error }) => !!error);
          if (columnErr) {
            toast.update(toastId, `Error duplicating page view columns`, {
              type: TOAST_TYPE.ERROR,
              autoClose: 2000
            });
          } else {
            toast.update(toastId, `Page view duplicated successfully!`, {
              type: TOAST_TYPE.SUCCESS,
              autoClose: 2000
            });
            onSuccess?.(newPageView?.[0]?.id);
          }
        }
      }
    },
    [addRecordAsync]
  );

  return {
    handleSavePageView,
    handleSaveViewColumnProp,
    deleteViewColumn,
    addColumnToPageView,
    handleUpdateMultiViewColumnsProps,
    handleAddNewPageView,
    handleDeleteView,
    handleDuplicatePageView,
    handleSaveView,
    deleteMultipleViewColumns,
    handleAddMultipleColumnToPageView
  };
};

export default useUpsertDeletePageViewConfig;
