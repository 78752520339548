import { useMutation } from "@tanstack/react-query";
import { PostgrestError, SupabaseClient } from "@supabase/supabase-js";
import useSupabaseBrowser from "utils/supabaseBrowserClient";

import { removeRecordByCompositePrimaryKey, removeRecordById } from "lib/supabaseApi";
import type { RemoveMultiInput, RemoveResponse } from "types/apiTypes";
import { ERROR_TYPES } from "utils/constants";

import useErrorLogger from "./useErrorLogger";

const removeMultiRecords = async (inputVal: RemoveMultiInput, supabaseClient: SupabaseClient) => {
  const { tableName, input } = inputVal;
  const promises = input.map(({ id, compositePrimaryKey }) =>
    compositePrimaryKey
      ? removeRecordByCompositePrimaryKey({ tableName, compositePrimaryKey }, supabaseClient)
      : removeRecordById({ tableName, id }, supabaseClient)
  );
  const results = await Promise.all(promises);
  return { data: results, error: null };
};

const useRemoveMultipleRecords = () => {
  const supabaseClient = useSupabaseBrowser();
  const { logError } = useErrorLogger();

  const { mutateAsync, isPending } = useMutation<RemoveResponse, unknown, RemoveMultiInput>({
    // @ts-ignore
    mutationFn: ({ tableName, input }: RemoveMultiInput) => removeMultiRecords({ tableName, input }, supabaseClient),
    onError: (error, variables) => {
      logError({
        error: error as PostgrestError,
        source: "useRemoveMultipleRecords",
        type: ERROR_TYPES.HOOKS,
        message: (error as PostgrestError).message || "Error removing multiple records",
        url: window.location.href,
        additionalInfo: {
          variables
        }
      });
    }
  });

  return {
    removeMultiRecordsAsync: mutateAsync,
    isLoading: isPending
  };
};

export default useRemoveMultipleRecords;
