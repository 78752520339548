import { useMemo } from "react";

import Radio from "components/Radio";

import { ComboBoxOption } from "components/ui/ComboBox";
import { ApiFormCustomDefaultValue } from "types/apiTypes";
import { FORM_DEFAULT_VALUE_TYPE, LookupTypes } from "utils/constants";
import { ExtendedSchema } from "utils/schema";
import CustomDefaultValueUI from "./CustomDefaultValueUI";

const FormCustomDefaultValue = ({
  defaultValue,
  handleDefaultValueChange,
  pageColumnOptions,
  extendedSchema,
  isBypassDB,
  hasWebhook
}: {
  defaultValue: ApiFormCustomDefaultValue;
  handleDefaultValueChange: (
    defaultValueId: string,
    updatedDefaultValue?: ApiFormCustomDefaultValue,
    isDelete?: boolean
  ) => void;
  pageColumnOptions: ComboBoxOption[];
  extendedSchema?: ExtendedSchema;
  isBypassDB?: boolean;
  hasWebhook?: boolean;
}) => {
  const selectedColumn = useMemo(() => {
    if (!defaultValue?.columnId) return null;
    return pageColumnOptions.find((option) => option.value === defaultValue?.columnId);
  }, [defaultValue?.columnId, pageColumnOptions]);

  const finalPageColumnOptions = useMemo(() => {
    if (!pageColumnOptions) return [];
    if (hasWebhook) return pageColumnOptions;

    return pageColumnOptions.filter(
      (option) => !option.column?.isLookup || option.column?.lookupPath?.["0"]?.lookupType === LookupTypes.FOREIGN
    );
  }, [pageColumnOptions, hasWebhook]);

  return (
    <div className="border-1 m-3 flex flex-col border p-4">
      <div className="my-2 flex w-full flex-row">
        {hasWebhook ? (
          <Radio
            label="Custom Value"
            className="mr-4"
            checked={defaultValue?.type === FORM_DEFAULT_VALUE_TYPE.CUSTOM}
            onChange={(e) => {
              handleDefaultValueChange(defaultValue.id, {
                ...defaultValue,
                type: !!e.target.checked ? FORM_DEFAULT_VALUE_TYPE.CUSTOM : undefined
              });
            }}
          />
        ) : null}
        {isBypassDB || !hasWebhook ? (
          <Radio
            label="Page Column"
            className="mr-4"
            checked={
              defaultValue?.type &&
              [FORM_DEFAULT_VALUE_TYPE.LOOKUP_COLUMN, FORM_DEFAULT_VALUE_TYPE.BASIC_COLUMN].includes(defaultValue?.type)
            }
            onChange={(e) => {
              handleDefaultValueChange(defaultValue.id, {
                ...defaultValue,
                type:
                  !!e.target.checked && selectedColumn?.isLookup
                    ? FORM_DEFAULT_VALUE_TYPE.LOOKUP_COLUMN
                    : FORM_DEFAULT_VALUE_TYPE.BASIC_COLUMN
              });
            }}
          />
        ) : null}
      </div>
      <CustomDefaultValueUI
        selectedColumn={selectedColumn}
        pageColumnOptions={finalPageColumnOptions}
        extendedSchema={extendedSchema}
        handleDefaultValueChange={handleDefaultValueChange}
        defaultValue={defaultValue}
        isBypassDB={isBypassDB}
      />
    </div>
  );
};

export default FormCustomDefaultValue;
