import { useEffect, useState } from "react";
import { v4 } from "uuid";

import Button from "components/Button";
import Select from "components/Select";
import TextField from "components/TextField";
import Toggle from "components/Toggle";

import useAllCustomViews from "hooks/useAllCustomViews";

import { ComboBox, ComboBoxOption } from "components/ui/ComboBox";
import { ApiFormCustomDefaultValue, ApiFormViewAdditionalConfig } from "types/apiTypes";
import { TableViewConfig } from "types/baTypes";
import { RecordItem, SelectOption } from "types/common";
import { ButtonSize, FORM_DEFAULT_VALUE_TYPE } from "utils/constants";
import { ExtendedSchema } from "utils/schema";
import FormCustomDefaultValue from "./FormCustomDefaultValue";

const FormConfigUI = ({
  viewConfig,
  formPagesOptions,
  handleChange,
  isChildPage,
  formRelationColumnOptions,
  handleAddFormRelationFieldColumn,
  isWebhookRequired,
  pageFormViewOptions,
  pageColumnOptions,
  handleDefaultValueChange,
  handleAddDefaultValue,
  extendedSchema,
  isStaticCustomForm
}: {
  viewConfig?: TableViewConfig;
  formPagesOptions?: SelectOption[];
  pageFormViewOptions?: SelectOption[];
  handleChange: (props: {
    newViewConfig: Partial<TableViewConfig>;
    newAdditionalConfig: ApiFormViewAdditionalConfig;
  }) => void;
  isChildPage?: boolean;
  formRelationColumnOptions?: ComboBoxOption[];
  handleAddFormRelationFieldColumn?: (formRelationColumn: RecordItem) => void;
  isWebhookRequired?: boolean;
  pageColumnOptions?: ComboBoxOption[];
  handleDefaultValueChange?: (
    defaultValueId: string,
    updatedDefaultValue?: ApiFormCustomDefaultValue,
    isDelete?: boolean
  ) => void;
  handleAddDefaultValue?: () => void;
  extendedSchema?: ExtendedSchema;
  isStaticCustomForm?: boolean;
}) => {
  const { options: customViewOptions } = useAllCustomViews();
  const [formSubmitWebhookUrl, setFormSubmitWebhookUrl] = useState<string>(
    viewConfig?.additionalConfig?.formSubmitWebhook?.url || ""
  );
  const [formRelationColumn, setFormRelationColumn] = useState<ComboBoxOption | undefined>(undefined);

  useEffect(() => {
    setFormSubmitWebhookUrl(viewConfig?.additionalConfig?.formSubmitWebhook?.url || "");
  }, [viewConfig?.additionalConfig?.formSubmitWebhook?.url]);
  return (
    <>
      {viewConfig?.additionalConfig?.multiFormEnabled ? (
        <section className="mt-7 flex flex-col">
          <div className="text-neutral-800 mb-2 text-lg">Select Multi Create Form Views</div>
          <div className="flex flex-row">
            <Select
              className="mr-5 max-w-[350px]"
              label="Select Page Form Views"
              value={pageFormViewOptions?.filter((viewOption) =>
                viewConfig?.additionalConfig?.multiPageFormViewIds?.includes(viewOption.pageView.viewId)
              )}
              options={pageFormViewOptions}
              multiple
              onChange={(opts: SelectOption | SelectOption[] | null) => {
                handleChange({
                  newAdditionalConfig: {
                    multiPageFormViewIds: opts?.map((opt: SelectOption) => opt.value)
                  },
                  newViewConfig: {}
                });
              }}
              disabled={!!isChildPage}
            />
            <Select
              className="mr-5 max-w-[350px]"
              label="Select Additional Custom Views"
              value={customViewOptions?.filter((viewOption) =>
                viewConfig?.additionalConfig?.multiFormViewIds?.includes(viewOption.view.id)
              )}
              options={customViewOptions}
              multiple
              onChange={(opts: SelectOption | SelectOption[] | null) => {
                handleChange({
                  newAdditionalConfig: {
                    multiFormViewIds: opts?.map((opt: SelectOption) => opt.value)
                  },
                  newViewConfig: {}
                });
              }}
              disabled={!!isChildPage}
            />
            <Select
              className="max-w-[350px]"
              label="Select Pages"
              value={formPagesOptions?.filter((pageOption) =>
                viewConfig?.additionalConfig?.multiFormPageIds?.includes(pageOption.page.id)
              )}
              options={formPagesOptions}
              multiple
              onChange={(opts: SelectOption | SelectOption[] | null) => {
                handleChange({
                  newAdditionalConfig: {
                    multiFormPageIds: opts?.map((opt: SelectOption) => opt.value)
                  },
                  newViewConfig: {}
                });
              }}
              disabled={!!isChildPage}
            />
          </div>
          <Toggle
            position="left"
            label="Hide default form?"
            checked={!!viewConfig?.additionalConfig?.hideDefaultInMultiForm}
            onChange={(checked) => {
              handleChange({
                newAdditionalConfig: {
                  hideDefaultInMultiForm: checked
                },
                newViewConfig: {}
              });
            }}
            disabled={!!isChildPage}
          />
        </section>
      ) : null}
      <>
        <section className="mt-7 flex flex-col">
          <div className="text-neutral-800 mb-3 text-lg">Form Webhook Settings</div>
          <div className="flex flex-col gap-3">
            <Toggle
              position="left"
              label="Has Form Webhook"
              checked={!!viewConfig?.additionalConfig?.formSubmitWebhook}
              onChange={(checked) => {
                if (!checked) {
                  handleChange({
                    newAdditionalConfig: {
                      formSubmitWebhook: undefined
                    },
                    newViewConfig: {}
                  });
                  return;
                }
                handleChange({
                  newAdditionalConfig: {
                    formSubmitWebhook: {
                      url: ""
                    }
                  },
                  newViewConfig: {}
                });
              }}
              disabled={!!isChildPage || isStaticCustomForm}
            />
            <Toggle
              position="left"
              label="Wait for Success"
              checked={!!viewConfig?.additionalConfig?.waitForSuccess}
              onChange={(checked) => {
                handleChange({
                  newAdditionalConfig: {
                    waitForSuccess: checked
                  },
                  newViewConfig: {}
                });
              }}
              disabled={!!isChildPage}
            />
            <Toggle
              position="left"
              label="Bypass DB Insert"
              checked={!!viewConfig?.additionalConfig?.byPassDbInsert}
              onChange={(checked) => {
                handleChange({
                  newAdditionalConfig: {
                    byPassDbInsert: checked
                  },
                  newViewConfig: {}
                });
              }}
              disabled={!!isChildPage || isStaticCustomForm}
            />
            {viewConfig?.additionalConfig?.formSubmitWebhook ? (
              <TextField
                label="Webhook URL"
                value={formSubmitWebhookUrl}
                onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                  setFormSubmitWebhookUrl(value);
                }}
                onBlur={() => {
                  handleChange({
                    newAdditionalConfig: {
                      formSubmitWebhook: {
                        url: formSubmitWebhookUrl
                      }
                    },
                    newViewConfig: {}
                  });
                }}
                disabled={!!isChildPage}
                required={isWebhookRequired}
                className="max-w-[350px]"
              />
            ) : null}
          </div>
        </section>
        {!!formRelationColumnOptions?.length && (
          <div className="flex items-center gap-4">
            <div className="mt-3 min-w-[300px] space-y-3">
              <div className="text-md">LookupTable Form Field</div>
              <ComboBox
                triggerBtnClassName="w-[300px]"
                defaultLabel="Select"
                options={formRelationColumnOptions}
                defaultValue={formRelationColumn?.value}
                onSelect={(_, option) => {
                  setFormRelationColumn(option);
                }}
                disabled={!!isChildPage}
              />
            </div>
            <Button
              size={ButtonSize.SM}
              label="Add Field"
              onClick={() => handleAddFormRelationFieldColumn?.(formRelationColumn as RecordItem)}
              disabled={!!isChildPage}
            />
          </div>
        )}
        {!!(typeof handleDefaultValueChange === "function") ? (
          <section className="mt-2 flex flex-col">
            <Toggle
              position="left"
              label="Has Custom Defaults"
              descriptor="Custom values are only available if a webhook url is set"
              checked={!!viewConfig?.additionalConfig?.hasDefaultValues}
              onChange={(checked) => {
                handleChange({
                  newAdditionalConfig: {
                    hasDefaultValues: checked,
                    defaultValues: checked
                      ? [
                          {
                            id: v4(),
                            type: !viewConfig?.additionalConfig?.formSubmitWebhook
                              ? FORM_DEFAULT_VALUE_TYPE.BASIC_COLUMN
                              : FORM_DEFAULT_VALUE_TYPE.CUSTOM,
                            value: ""
                          }
                        ]
                      : undefined
                  },
                  newViewConfig: {}
                });
              }}
              disabled={!!isChildPage}
            />
            {!!viewConfig?.additionalConfig?.defaultValues?.length &&
              viewConfig?.additionalConfig?.defaultValues?.map((defaultValue, key) => (
                <FormCustomDefaultValue
                  key={key}
                  defaultValue={defaultValue}
                  handleDefaultValueChange={handleDefaultValueChange}
                  pageColumnOptions={pageColumnOptions || []}
                  extendedSchema={extendedSchema}
                  isBypassDB={!!viewConfig?.additionalConfig?.byPassDbInsert}
                  hasWebhook={!!viewConfig?.additionalConfig?.formSubmitWebhook}
                />
              ))}
            {!!viewConfig?.additionalConfig?.hasDefaultValues ? (
              <div className="flex flex-row-reverse pr-4">
                <Button label="Add Default Value" className="mt-4 " onClick={handleAddDefaultValue} />
              </div>
            ) : null}
          </section>
        ) : null}
      </>
    </>
  );
};

export default FormConfigUI;
