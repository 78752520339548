import { Trash2 as DeleteIcon } from "lucide-react";
import { useMemo, useState } from "react";

import TextField from "components/TextField";
import Toggle from "components/Toggle";

import IconButton from "components/IconButton";
import { ComboBox, ComboBoxOption, ComboBoxValue } from "components/ui/ComboBox";
import { ApiFormCustomDefaultValue } from "types/apiTypes";
import { RecordItem } from "types/common";
import {
  FORM_DEFAULT_VALUE_TYPE,
  FORM_SPECIAL_DEFAULTS_OPTIONS,
  LookupTypes,
  SPECIAL_DEFAULTS,
  SPECIAL_DEFAULTS_OPTIONS
} from "utils/constants";
import { ExtendedSchema } from "utils/schema";

const CustomDefaultValueUI = ({
  defaultValue,
  pageColumnOptions,
  selectedColumn,
  handleDefaultValueChange,
  extendedSchema,
  isBypassDB
}: {
  defaultValue: ApiFormCustomDefaultValue;
  handleDefaultValueChange: (
    defaultValueId: string,
    updatedDefaultValue?: ApiFormCustomDefaultValue,
    isDelete?: boolean
  ) => void;
  pageColumnOptions: ComboBoxOption[];
  selectedColumn?: ComboBoxOption | null;
  extendedSchema?: ExtendedSchema;
  isBypassDB?: boolean;
}) => {
  const [defaultCustomField, setDefaultCustomField] = useState<string>(defaultValue?.customField || "");
  const [defaultCustomValue, setDefaultCustomValue] = useState<string>((defaultValue?.value as string) || "");

  const [joinDefaultValue, setJoinDefaultValue] = useState<RecordItem | undefined>(defaultValue?.value as RecordItem);
  // Tracks lookup join table composite key defaults
  const compsiteKeys = useMemo(() => {
    if (
      !selectedColumn?.column?.lookupPath?.["0"]?.lookupTableName ||
      selectedColumn?.column?.lookupPath?.["0"]?.lookupType !== LookupTypes.JOIN ||
      !extendedSchema
    )
      return [];
    const joinTable = extendedSchema[selectedColumn?.column?.lookupPath?.["0"]?.lookupTableName];
    if (!joinTable?.compositePk?.length) return [];
    return joinTable.compositePk
      ?.filter((pKey) => pKey.attributeId !== "id")
      .map((pKey) => ({
        value: pKey.attributeId,
        title: pKey.attributeId,
        pKey
      }));
  }, [selectedColumn, extendedSchema]);

  const isSpecialDefault = [
    SPECIAL_DEFAULTS.CURRENT_PROJECT_ID,
    SPECIAL_DEFAULTS.CURRENT_RECORD_ID,
    SPECIAL_DEFAULTS.NEW_RECORD_ID
  ].includes(defaultValue?.value as string);

  const isJoinTableDefault =
    defaultValue?.type &&
    [FORM_DEFAULT_VALUE_TYPE.LOOKUP_COLUMN, FORM_DEFAULT_VALUE_TYPE.BASIC_COLUMN].includes(defaultValue?.type) &&
    selectedColumn?.column?.lookupPath?.["0"]?.lookupType === LookupTypes.JOIN &&
    compsiteKeys?.length;

  return (
    <>
      {defaultValue?.type &&
      [FORM_DEFAULT_VALUE_TYPE.LOOKUP_COLUMN, FORM_DEFAULT_VALUE_TYPE.BASIC_COLUMN].includes(defaultValue?.type) &&
      selectedColumn?.column?.lookupPath?.["0"]?.lookupTableName &&
      selectedColumn?.column?.lookupPath?.["0"]?.lookupType === LookupTypes.FOREIGN ? (
        <div className="flex flex-row text-xs">
          {selectedColumn?.column?.lookupPath?.["0"]?.lookupForeignKey}
          {defaultCustomValue ? ` = ${defaultCustomValue}` : ""}
        </div>
      ) : null}
      <div className="flex flex-row justify-between">
        {defaultValue?.type &&
        [FORM_DEFAULT_VALUE_TYPE.LOOKUP_COLUMN, FORM_DEFAULT_VALUE_TYPE.BASIC_COLUMN].includes(defaultValue?.type) ? (
          <div className="flex flex-row py-3">
            <div className="mr-2 flex-1 flex-col">
              <div className="space-y-3">
                <div className="text-sm">Select Column</div>
                <ComboBox
                  defaultLabel="Select Column"
                  options={pageColumnOptions}
                  defaultValue={selectedColumn?.value}
                  onSelect={(value, option) => {
                    handleDefaultValueChange(defaultValue.id, {
                      ...defaultValue,
                      columnId: value as ComboBoxValue,
                      type: (option as ComboBoxOption)?.column?.isLookup
                        ? FORM_DEFAULT_VALUE_TYPE.LOOKUP_COLUMN
                        : FORM_DEFAULT_VALUE_TYPE.BASIC_COLUMN
                    });
                  }}
                />
              </div>

              <div className="mt-2 text-xs">
                (If lookup column is selected related foreign key is updated with default value, for join table, all
                composite key values will need to be set)
              </div>
            </div>
            {!isJoinTableDefault ? (
              <>
                {isSpecialDefault ? (
                  <div className="mr-2">
                    <div className="space-y-3">
                      <div className="text-sm">Special Defaults</div>
                      <ComboBox
                        defaultLabel="Special Defaults"
                        options={SPECIAL_DEFAULTS_OPTIONS}
                        defaultValue={defaultValue?.value as ComboBoxValue}
                        onSelect={(value) => {
                          handleDefaultValueChange(defaultValue.id, {
                            ...defaultValue,
                            value: value as ComboBoxValue
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <TextField
                    className="mr-2"
                    value={defaultCustomValue as string}
                    label="Default Value"
                    onChange={(e) => setDefaultCustomValue(e.target.value)}
                    onBlur={() =>
                      handleDefaultValueChange(defaultValue.id, {
                        ...defaultValue,
                        value: defaultCustomValue as string
                      })
                    }
                  />
                )}
                <div className="ml-4">
                  <Toggle
                    label="Pick Special Default"
                    checked={isSpecialDefault}
                    onChange={(checked) => {
                      handleDefaultValueChange(defaultValue.id, {
                        ...defaultValue,
                        value: checked ? SPECIAL_DEFAULTS.CURRENT_PROJECT_ID : ""
                      });
                      setDefaultCustomValue("");
                    }}
                    isLabelSmall
                  />
                </div>
              </>
            ) : (
              <div className="flex w-full flex-1 flex-col text-xs">
                {compsiteKeys?.map((pKey) => {
                  const isValueSpecialDefault = [
                    SPECIAL_DEFAULTS.CURRENT_PROJECT_ID,
                    SPECIAL_DEFAULTS.CURRENT_RECORD_ID
                  ].includes((defaultValue?.value as RecordItem)?.[pKey.value]);
                  return (
                    <div key={pKey.value} className="m-3 flex flex-row">
                      <span className="mr-3">{pKey.value}</span>
                      {isValueSpecialDefault ? (
                        <div className="mr-2">
                          <div className="space-y-3">
                            <div className="text-sm">Special Defaults</div>
                            <ComboBox
                              defaultLabel="Special Defaults"
                              options={SPECIAL_DEFAULTS_OPTIONS}
                              defaultValue={(defaultValue?.value as RecordItem)?.[pKey.value]}
                              onSelect={(value) => {
                                handleDefaultValueChange(defaultValue.id, {
                                  ...defaultValue,
                                  value: {
                                    ...((defaultValue.value as RecordItem) || {}),
                                    [pKey.value]: value || ""
                                  }
                                });
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <TextField
                          value={joinDefaultValue?.[pKey.value] || ""}
                          label="Custom Value"
                          onChange={(e) => setJoinDefaultValue((prev) => ({ ...prev, [pKey.value]: e.target.value }))}
                          onBlur={() =>
                            handleDefaultValueChange(defaultValue.id, {
                              ...defaultValue,
                              value: {
                                ...((defaultValue.value as RecordItem) || {}),
                                ...joinDefaultValue
                              }
                            })
                          }
                        />
                      )}
                      <div className="ml-4">
                        <Toggle
                          label="Pick Special Default"
                          checked={isValueSpecialDefault}
                          onChange={(checked) => {
                            handleDefaultValueChange(defaultValue.id, {
                              ...defaultValue,
                              value: {
                                ...((defaultValue.value as RecordItem) || {}),
                                [pKey.value]: checked ? SPECIAL_DEFAULTS.CURRENT_PROJECT_ID : ""
                              }
                            });

                            setJoinDefaultValue((prev) => ({ ...prev, [pKey.value]: "" }));
                          }}
                          isLabelSmall
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ) : null}
        {defaultValue?.type === FORM_DEFAULT_VALUE_TYPE.CUSTOM ? (
          <div className="flex flex-row">
            <TextField
              className="mr-3"
              value={defaultCustomField as string}
              label="Custom Field"
              onChange={(e) => setDefaultCustomField(e.target.value)}
              onBlur={() =>
                handleDefaultValueChange(defaultValue.id, {
                  ...defaultValue,
                  customField: defaultCustomField as string
                })
              }
            />
            <>
              {isSpecialDefault ? (
                <div className="mr-2">
                  <div className="space-y-3">
                    <div className="text-sm">Special Defaults</div>
                    <ComboBox
                      defaultLabel="Special Defaults"
                      options={isBypassDB ? SPECIAL_DEFAULTS_OPTIONS : FORM_SPECIAL_DEFAULTS_OPTIONS}
                      defaultValue={defaultValue?.value as ComboBoxValue}
                      onSelect={(value) => {
                        handleDefaultValueChange(defaultValue.id, {
                          ...defaultValue,
                          value: value as string
                        });
                      }}
                    />
                  </div>
                </div>
              ) : (
                <TextField
                  className="mr-2"
                  value={defaultCustomValue as string}
                  label="Default Value"
                  onChange={(e) => setDefaultCustomValue(e.target.value)}
                  onBlur={() =>
                    handleDefaultValueChange(defaultValue.id, {
                      ...defaultValue,
                      value: defaultCustomValue as string
                    })
                  }
                />
              )}
              <div className="ml-4">
                <Toggle
                  label="Pick Special Default"
                  checked={isSpecialDefault}
                  onChange={(checked) => {
                    handleDefaultValueChange(defaultValue.id, {
                      ...defaultValue,
                      value: checked ? SPECIAL_DEFAULTS.CURRENT_PROJECT_ID : ""
                    });
                    setDefaultCustomValue("");
                  }}
                  isLabelSmall
                />
              </div>
            </>
          </div>
        ) : null}
        <IconButton
          icon={DeleteIcon}
          onClick={() => {
            handleDefaultValueChange(defaultValue.id, undefined, true);
          }}
          type="button"
        />
      </div>
    </>
  );
};

export default CustomDefaultValueUI;
