import { IncomingHttpHeaders } from "http";
import { RecordItem } from "types/common";
import {
  ViewSection,
  TableColumnType,
  TableViewConfig,
  ViewColumn,
  AlgoliaSearchTable,
  MenuItem,
  TablePageViewTab,
  UserHistory
} from "types/baTypes";
import { ApiAlgoliaSearchTable, ApiColumn, ApiUserHistory } from "types/apiTypes";
import { USER_TYPE, USER_TYPE_SUB_DOMAINS, ViewOption } from "./constants";

const IS_DEV_ENV = !!process.env.IS_APP_LOCAL;

export const getRedirectUrlFromRequestHeadersForUserType = (headers: IncomingHttpHeaders, userType: USER_TYPE) => {
  let subdomain = headers?.host?.split(".")?.[0];
  subdomain =
    subdomain?.includes("airstudio") && subdomain !== "airstudio" // this check is to handle preview deployments
      ? subdomain
      : USER_TYPE_SUB_DOMAINS[userType] || subdomain;
  const domain = headers.host?.split(".").slice(1).join(".");
  const httpMethod = IS_DEV_ENV ? "http://" : "https://";
  return `${httpMethod}${subdomain}.${domain}`;
};

export const getUserType = (headers: RecordItem, userTypeQueryParam?: string) => {
  const originVal = headers?.get("host");
  if (userTypeQueryParam && originVal?.includes("airstudio")) {
    return userTypeQueryParam as USER_TYPE;
  }

  return originVal?.startsWith("client")
    ? USER_TYPE.CLIENT
    : originVal?.startsWith("pro")
      ? USER_TYPE.PRO
      : USER_TYPE.STAFF;
};

export const getLoginUrlWithTokenFromUserType = (userType: USER_TYPE, token: string) =>
  `https://${USER_TYPE_SUB_DOMAINS[userType]}.buildappeal.com/login?user_token=${token}`;

export const getColumnApiFieldsFromTableColumn = (tableColumn: Partial<TableColumnType>) => {
  const finalInput: Partial<ApiColumn> = {
    id: tableColumn.id,
    name: tableColumn.name,
    header: tableColumn.header,
    description: tableColumn.description,
    nickname: tableColumn.nickname,
    has_filter: tableColumn.hasFilter,
    filter_sort_order: tableColumn.filterSortOrder,
    has_bulk_edit: tableColumn.hasBulkEdit,
    type: tableColumn.type,
    is_lookup: tableColumn.isLookup,
    is_formula: tableColumn.isFormula,
    lookup_path: tableColumn.lookupPath,
    column_options_lookup: tableColumn.columnOptionsLookUp,
    column_options_sort: tableColumn.columnOptionSortOrder,
    column_options_group_sort: tableColumn.columnGroupBySortOrder,
    is_multiple: tableColumn.isMultiple,
    is_editable: tableColumn.isEditable,
    is_inline_editable: tableColumn.enableInlineEditing,
    is_select: tableColumn.isSelect,
    is_rollup_source: tableColumn.isRollupSource,
    db_type: tableColumn.dbType,
    create_page_id: tableColumn.createPageId || null,
    create_page_defaults: tableColumn.createPageDefaultValues || null,
    formula: tableColumn.formula,
    rollup_config: tableColumn.rollupConfig,
    config_errors: tableColumn.configErrors,
    write_rollup_config: tableColumn.writeRollupConfig,
    parent_col_id: tableColumn.syncedParentColId,
    cell_config: tableColumn.cellConfig
  };
  if (tableColumn.defaultValues || tableColumn.defaultValues === null) {
    finalInput["default_values"] = tableColumn.defaultValues;
  }
  return finalInput;
};

export const getPageColumnApiFieldsFromColumn = (params: {
  id: string;
  isAdminColumn?: boolean;
  isActive?: boolean;
}) => {
  return {
    id: params.id,
    is_admin_column: params.isAdminColumn,
    is_active: params.isActive
  };
};

export const getViewApiFieldsFromPageView = (pageView: Partial<TableViewConfig>) => {
  return {
    id: pageView.id,
    view_type: pageView.viewType,
    multiselect_enabled: pageView.multiselectEnabled,
    display_with_icons: pageView.displayWithIcons,
    default_page_tab: pageView.defaultPageTab,
    additional_config: pageView.additionalConfig || null,
    disable_expand: pageView.disableExpand,
    is_active: pageView.isActive,
    is_custom: pageView.isCustom,
    title: pageView.title,
    sub_type: pageView.subType,
    is_default: pageView.isDefault
  };
};

export const getViewColumnApiFieldsFromViewColumn = (viewCol: Partial<ViewColumn>) => {
  return {
    id: viewCol.id,
    is_hidden: viewCol?.isHidden,
    sort: viewCol.sort,
    form_relation_page_id: viewCol.formRelationPageId,
    pin_direction: viewCol.pinDirection,
    has_divider: viewCol.hasDivider,
    is_required: viewCol.isRequired,
    is_group_by: viewCol.isGroupBy,
    is_group_by_second: viewCol.isGroupBySecond,
    is_featured_image: viewCol.isFeaturedImage
  };
};

export const getViewColumnSortApiFieldsFromViewColumn = (
  column: TableColumnType,
  viewType: ViewOption,
  viewConfig?: TableViewConfig
) => {
  const isPageViewCustom = viewConfig?.isPageViewCustom;
  if (
    (!isPageViewCustom && !column?.sortOrder) ||
    (isPageViewCustom && !column?.customPageViews?.[viewConfig?.viewId || ""]?.sort)
  ) {
    return null;
  }
  const viewSort = isPageViewCustom
    ? column?.customPageViews?.[viewConfig?.viewId || ""]?.sort
    : column.sortOrder?.[viewType as ViewOption];
  const viewColConfig = isPageViewCustom
    ? column?.customPageViews?.[viewConfig?.viewId || ""]
    : column.views?.[viewType];
  if (!viewColConfig) {
    return null;
  }
  return {
    id: viewColConfig.id,
    sort: viewSort || ""
  };
};

export const getSectionApiFieldsFromViewSections = (section: Partial<ViewSection>) => {
  return {
    id: section.id,
    title: section.title,
    description: section.description,
    is_close: section.isClose,
    sort: section.sort,
    webhook_url: section.webhookUrl,
    is_admin_only: section.isAdminOnly,
    config: section.config,
    view_id: section.viewId,
    for_user_id: section.userId,
    page_view_id: section.pageViewId
  };
};

export const getTabApiFieldsFromPageTabs = (section: TablePageViewTab) => {
  return {
    id: section.id,
    tab_page_id: section.page?.id,
    default_view: section.defaultView,
    sidebar_default_view: section.sidebarDefaultView,
    title: section.title,
    icon: section.icon,
    visibility: section.visibility,
    sort: section.sort,
    filter_relations: section.filterRelations,
    add_page_id: section.addPage?.id,
    recordid_source: section.recordIdSource,
    ui_menu_item_id: section.uiMenuItemId,
    default_add_expanded: section.defaultAddExpanded,
    show_record_count: section.showRecordCount
  };
};

export const getPageTabApiFieldsFromPageTabs = (section: TablePageViewTab) => {
  return {
    id: section.id,
    is_child: section.isChild,
    is_hidden: section.isHidden
  };
};

// Only returns algolia_search_tables fields, columns not returned
export const getApiAlogliaSearchTableFields = (searchTable: AlgoliaSearchTable): ApiAlgoliaSearchTable => {
  return {
    id: searchTable.id,
    index_name: searchTable.indexName,
    table_name: searchTable.tableName,
    sort_order: searchTable.sortOrder,
    result_config: searchTable.resultConfig,
    title: searchTable.title,
    has_create_action: searchTable.hasCreateAction,
    enable_master_index: searchTable.enableMasterIndex,
    master_index_config: searchTable.masterIndexConfig,
    is_reserved_index: searchTable.isReservedIndex,
    additional_config: searchTable.additionalConfig,
    is_active: searchTable.isActive
  };
};

export const getMenuItemApiFieldsFromMenuItem = (menuItem: MenuItem) => {
  return {
    id: menuItem.id,
    name: menuItem.name,
    icon: menuItem.icon,
    is_folder: menuItem.is_folder,
    sort_order: menuItem.sort_order,
    parent_id: menuItem.parent_id,
    ui_page_id: menuItem.ui_page_id?.id,
    ui_menu_id: menuItem.ui_menu_id,
    is_divider: menuItem.is_divider,
    is_section: menuItem.is_section,
    url: menuItem.url,
    record_config: menuItem.record_config,
    open_in_new_tab: !!menuItem.open_in_new_tab,
    show_admin_only: !!menuItem.show_admin_only,
    show_record_count: !!menuItem.show_record_count,
    additional_config: menuItem.additionalConfig
  };
};

export const getUserHistoryApiFieldsFromUserHistory = (userHistory: Partial<UserHistory>) => {
  return {
    id: userHistory.id,
    people_id: userHistory.peopleId,
    page_path: userHistory.pagePath,
    page_title: userHistory.pageTitle,
    additional_fields: userHistory.additionalFields
  };
};
