import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { getAllCustomViews } from "lib/adminApi";
import { USER_TYPE } from "utils/constants";
import useCurrentUser from "./useCurrentUser";

const useAllCustomViews = () => {
  const supabaseClient = useSupabaseBrowser();
  const currentUser = useCurrentUser();

  const { data, isLoading, error } = useQuery({
    queryKey: ["allCustomViews", currentUser?.org_id],
    queryFn: () => getAllCustomViews(supabaseClient, currentUser?.org_id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    enabled: !!currentUser?.id && currentUser?.type === USER_TYPE.STAFF
  });

  const options = useMemo(() => {
    if (!data) return [];
    return data?.data?.map((view) => ({
      title: `${view.title} (Custom ${view.view_type})`,
      value: view.id,
      view
    }));
  }, [data]);

  return {
    data: data?.data,
    isLoading,
    error,
    options
  };
};

export default useAllCustomViews;
