import React from "react";
import clsx from "clsx";

type RadioProps = {
  disabled?: boolean;
  label?: string;
  descriptor?: string;
  isLabelSmall?: boolean;
  checked?: boolean;
  className?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  mRef?: React.RefObject<HTMLLabelElement> | null;
};

const Radio = ({
  disabled = false,
  label = "",
  descriptor = "",
  isLabelSmall = false,
  className = "",
  mRef = null,
  ...rest
}: RadioProps) => {
  const clsChildren = "w-6 h-6 border rounded-full";
  return (
    <label data-testid="Radio" className={clsx("relative flex", className)} ref={mRef}>
      <input
        data-testid="Radio-input"
        className={clsx(clsChildren, "peer sr-only hidden")}
        type="radio"
        disabled={disabled}
        {...rest}
      />
      {/* Icon */}
      <svg
        data-testid="Radio-icon"
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        className={clsx(
          clsChildren,
          "mr-4 p-0.5",
          "hidden peer-checked:flex",
          "border-2 border-primary-700 fill-primary-700 peer-checked:text-primary-700",
          "peer-disabled:border-none peer-disabled:bg-neutral-300 peer-disabled:text-base-disabled"
        )}
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z" fill="currentColor" />
      </svg>
      <div
        className={clsx(
          clsChildren,
          "mr-4",
          "flex peer-checked:hidden",
          "border-separator bg-neutral-0",
          "peer-hover:border-base-secondary",
          "peer-disabled:border-none peer-disabled:bg-neutral-300"
        )}
      />
      {/* Texts */}
      <div className={clsx("flex-col align-baseline")}>
        <div
          className={clsx(
            disabled
              ? "text-base-disabled dark:text-base-dark-disabled"
              : "text-base-primary dark:text-base-dark-primary",
            descriptor ? "font-medium" : "font-normal",
            isLabelSmall ? "text-sm" : "text-base",
            isLabelSmall ? "pt-0.5" : ""
          )}
        >
          {label}
        </div>
        <div
          className={clsx(
            disabled
              ? "text-base-disabled dark:text-base-dark-disabled"
              : "text-base-primary dark:text-base-dark-primary",
            "text-sm",
            "mt-0.5"
          )}
        >
          {label ? descriptor : ""}
        </div>
      </div>
    </label>
  );
};

export default Radio;
