import { useCallback } from "react";
import { ColumnRule } from "types/baTypes";
import { COLUMN_RULE_TYPES, COLUMN_RULE_PARENT_TYPES } from "utils/constants";
import toast from "utils/toast";
import { RecordItem } from "types/common";
import useRemoveRecord from "./useRemoveRecord";
import useAddRecord from "./useAddRecord";
import useUpdateRecord from "./useUpdateRecord";
import useRemoveMultipleRecords from "./useRemoveMultipleRecords";
import useUpsertRecord from "./useUpsertRecord";

type AdditionalProps = {
  forPageId?: string;
  forColumnId?: string;
  type?: COLUMN_RULE_TYPES;
  columnIdForConditionalVisibility?: string;
  onSuccess?: () => void;
  ruleType?: COLUMN_RULE_PARENT_TYPES;
  forPageActionId?: string;
};

// This hook creates/updates an entry in `ui_column_rules` and all linked `ui_column_condition` entries
const useUpsertDeleteColumnRule = () => {
  const { addRecordAsync } = useAddRecord();
  const { updateRecordAsync } = useUpdateRecord();
  const { removeRecordAsync } = useRemoveRecord();
  const { removeMultiRecordsAsync } = useRemoveMultipleRecords();
  const { upsertRecordAsync } = useUpsertRecord();

  const addNewRulesAndConditions = useCallback(
    async (columnRules: ColumnRule[], additionalProps: AdditionalProps) => {
      for (const columnRule of columnRules) {
        const colRuleInput = {
          for_page_id: additionalProps.forPageId,
          for_page_action_id: additionalProps.forPageActionId,
          type: additionalProps.type || COLUMN_RULE_TYPES.CONDITIONAL_FORMATTING,
          rule_config: columnRule.ruleConfig
        };
        const { data: newColRuleData, error } = await addRecordAsync({
          tableName: "ui_column_rules",
          input: colRuleInput
        });
        if (error) {
          if (additionalProps.type !== COLUMN_RULE_TYPES.PAGE_ACTION_VISIBILITY) {
            toast.error("Error adding PageAction visibility rule!");
          } else {
            toast.error("Error adding column formatting rule!");
          }
          return;
        }
        if (newColRuleData?.[0]?.id) {
          const newRuleConditions = columnRule.columnRules;
          const ruleConditionsInput = newRuleConditions?.map((ruleCondition) => ({
            ui_page_column_id: ruleCondition?.pageColumnId || ruleCondition?.column?.pageColumnId,
            field: ruleCondition?.field,
            operator: ruleCondition?.operator,
            filter_group: ruleCondition?.filterGroup,
            value: ruleCondition?.value,
            column_rules_id: newColRuleData[0].id,
            lookup_column_name_field: ruleCondition?.lookupColumnNameField
          }));
          const { data: newConditionRules, error: conditionErr } = await addRecordAsync({
            tableName: "ui_column_condition",
            input: ruleConditionsInput
          });
          if (conditionErr) {
            if (additionalProps.type === COLUMN_RULE_TYPES.PAGE_ACTION_VISIBILITY) {
              toast.error("Error adding PageAction visibility rule!");
            } else {
              toast.error("Error adding column formatting rule!");
            }
            return;
          }

          // Used to link conditional visibility rule to a column
          if (newConditionRules?.length && additionalProps.columnIdForConditionalVisibility) {
            await updateRecordAsync({
              tableName: "ui_columns",
              input: {
                id: additionalProps.columnIdForConditionalVisibility,
                conditional_view_rule: newColRuleData[0].id
              }
            });
          }

          // Used to link conditional visibility rule to a pageAction
          if (newConditionRules?.length && additionalProps.forPageActionId) {
            await updateRecordAsync({
              tableName: "ui_pages_actions",
              input: {
                id: additionalProps.forPageActionId,
                conditional_view_rule: newColRuleData[0].id
              }
            });
          }
        }
      }
      additionalProps.onSuccess?.();
    },
    [addRecordAsync, updateRecordAsync]
  );

  const updateRulesAndConditions = useCallback(
    async (columnRules: ColumnRule[], additionalProps: AdditionalProps) => {
      if (!columnRules?.length) return;
      for (const columnRule of columnRules) {
        if (columnRule?.id) {
          const colRuleInput = {
            id: columnRule.id,
            rule_config: columnRule.ruleConfig
          };

          const { error } = await updateRecordAsync({
            tableName: "ui_column_rules",
            input: colRuleInput
          });
          if (error) {
            if (additionalProps?.type === COLUMN_RULE_TYPES.PAGE_ACTION_VISIBILITY) {
              toast.error("Error updating PageAction visibility rule!");
            } else {
              toast.error("Error updating column formatting rule!");
            }
            return;
          }
          const ruleConditions = columnRule.columnRules;
          const newRuleConditionsInput: RecordItem[] = [];
          const existingRuleConditionsInput: RecordItem[] = [];
          ruleConditions?.forEach((ruleCondition) => {
            const isNewRuleCondition = ruleCondition.id?.toString().startsWith("new_");
            if (isNewRuleCondition) {
              newRuleConditionsInput.push({
                ui_page_column_id: ruleCondition?.pageColumnId || ruleCondition?.column?.pageColumnId,
                field: ruleCondition?.field,
                operator: ruleCondition?.operator,
                filter_group: ruleCondition?.filterGroup,
                value: ruleCondition?.value,
                lookup_column_name_field: ruleCondition?.lookupColumnNameField,
                column_rules_id: columnRule.id
              });
            } else {
              existingRuleConditionsInput.push({
                id: ruleCondition.id,
                ui_page_column_id: ruleCondition?.pageColumnId || ruleCondition?.column?.pageColumnId,
                field: ruleCondition?.field,
                operator: ruleCondition?.operator,
                filter_group: ruleCondition?.filterGroup,
                value: ruleCondition?.value,
                lookup_column_name_field: ruleCondition?.lookupColumnNameField,
                column_rules_id: columnRule.id
              });
            }
          });

          const [newRuleConditionsResp, existingRuleConditionsResp] = await Promise.all([
            addRecordAsync({
              tableName: "ui_column_condition",
              input: newRuleConditionsInput
            }),
            upsertRecordAsync({
              tableName: "ui_column_condition",
              input: existingRuleConditionsInput
            })
          ]);
          if (newRuleConditionsResp.error || existingRuleConditionsResp.error) {
            if (additionalProps?.type === COLUMN_RULE_TYPES.PAGE_ACTION_VISIBILITY) {
              toast.error("Error updating PageAction visibility rule!");
            } else {
              toast.error("Error updating column formatting rule!");
            }
            return;
          }
        }
      }
      additionalProps.onSuccess?.();
    },
    [addRecordAsync, upsertRecordAsync, updateRecordAsync]
  );

  const deleteRuleConditions = useCallback(
    async (ruleConditionIds: string[], additionalProps?: AdditionalProps) => {
      await removeMultiRecordsAsync({
        tableName: "ui_column_condition",
        input: ruleConditionIds.map((ruleId) => ({ id: ruleId, tableName: "ui_column_condition" }))
      });
      additionalProps?.onSuccess?.();
    },
    [removeMultiRecordsAsync]
  );

  const deleteColumnRuleAndConditions = useCallback(
    async (rule: ColumnRule, additionalProps?: AdditionalProps) => {
      if (!rule?.id) return;

      // Remove page action visibility rule first
      if (additionalProps?.forPageActionId) {
        await updateRecordAsync({
          tableName: "ui_pages_actions",
          input: {
            id: additionalProps.forPageActionId,
            conditional_view_rule: null
          }
        });
      }

      // Remove column visibility rule first
      if (additionalProps?.columnIdForConditionalVisibility) {
        await updateRecordAsync({
          tableName: "ui_columns",
          input: {
            id: additionalProps.columnIdForConditionalVisibility,
            conditional_view_rule: null
          }
        });
      }

      const columnRules = rule.columnRules;
      if (columnRules?.length) {
        await deleteRuleConditions(columnRules.map((rule) => rule.id));

        if (additionalProps?.ruleType === COLUMN_RULE_PARENT_TYPES.UI_COLUMN_FILTER) {
          await removeRecordAsync({
            tableName: "ui_filters",
            id: rule.id
          });
        } else {
          await removeRecordAsync({
            tableName: "ui_column_rules",
            id: rule.id
          });
        }

        if (additionalProps?.type !== COLUMN_RULE_TYPES.PAGE_ACTION_VISIBILITY) {
          toast.success("Column formatting rule deleted successfully!");
        }
        additionalProps?.onSuccess?.();
      }
    },
    [removeRecordAsync, updateRecordAsync, deleteRuleConditions]
  );

  const upsertRules = useCallback(
    async (columnRules: ColumnRule[], additionalProps: AdditionalProps) => {
      const newRules = columnRules.filter((rule) => rule.id.toString().startsWith("new_"));
      const existingRules = columnRules.filter((rule) => !rule.id.toString().startsWith("new_"));
      if (newRules?.length) {
        await addNewRulesAndConditions(newRules, additionalProps);
      }
      if (existingRules?.length) {
        await updateRulesAndConditions(existingRules, additionalProps);
      }
    },
    [addNewRulesAndConditions, updateRulesAndConditions]
  );

  return {
    upsertRules,
    deleteColumnRuleAndConditions,
    deleteRuleConditions
  };
};

export default useUpsertDeleteColumnRule;
