import { useCallback } from "react";
import { ColumnRule, TableFilterType } from "types/baTypes";

import toast from "utils/toast";
import { COLUMN_RULE_PARENT_TYPES, APP_FILTER_TYPES } from "utils/constants";
import { getForeignKeyForFilterType, getTableNameForFilters } from "utils/filterUtils";
import useUpsertDeleteColumnRule from "./useUpsertDeleteColumnRule";
import useUpdateRecord from "./useUpdateRecord";
import useAddRecord from "./useAddRecord";
import useRemoveRecord from "./useRemoveRecord";

type AdditionalProps = {
  pageColumnId?: string;
  filterType: APP_FILTER_TYPES;
  onSuccess?: () => void;
  skipExitingValueUpdate?: boolean; // Used to skip updating existing values when adding new filters
  tabId?: string;
  pageId?: string;
  sectionId?: string;
  viewColumnId?: string;
  orgId?: string;
};
const useUpsertDeleteFilters = () => {
  const { deleteColumnRuleAndConditions } = useUpsertDeleteColumnRule();
  const { updateRecordAsync } = useUpdateRecord();
  const { addRecordAsync } = useAddRecord();
  const { removeRecordAsync } = useRemoveRecord();

  const handleFilterChange = useCallback(
    async (filters: TableFilterType[], additionalProps: AdditionalProps) => {
      if (!filters.length) return;
      const colFilterRule = filters[0].originalRuleItem;
      if (colFilterRule) {
        // Filter entry already exists update conditions
        const newConditions = filters.filter((filter) => !!filter.id?.toString().startsWith("new_"));
        const existingConditions = filters.filter((filter) => !filter.id?.toString().startsWith("new_"));
        if (newConditions?.length) {
          const newColConditionInputs = [];
          for (const colCondition of newConditions) {
            newColConditionInputs.push({
              filter_lookup_path: colCondition?.filterLookupPath,
              field: colCondition?.filterField,
              operator: colCondition?.filterOperator,
              value: colCondition?.filterValue,
              column_filters_id: colFilterRule.id,
              record_id_source: colCondition.recordIdSource,
              include_null: colCondition?.includeNull,
              is_parent_synced: colCondition?.isParentSynced,
              is_user_visible: colCondition?.isUserVisible,
              ui_page_column_id: colCondition?.pageColumnId,
              option_value: colCondition.filterOptionValue,
              filter_db_type: colCondition.filterDbType
            });
          }
          if (newColConditionInputs?.length) {
            await Promise.all([
              ...newColConditionInputs.map((input) => addRecordAsync({ tableName: "ui_column_condition", input }))
            ]);
          }
        }
        if (!additionalProps.skipExitingValueUpdate && existingConditions?.length) {
          const colConditionInputs = [];
          for (const colCondition of existingConditions) {
            colConditionInputs.push({
              id: colCondition.id,
              filter_lookup_path: colCondition?.filterLookupPath,
              field: colCondition?.filterField,
              operator: colCondition?.filterOperator,
              value: colCondition?.filterValue,
              column_filters_id: colFilterRule.id,
              record_id_source: colCondition.recordIdSource,
              include_null: colCondition?.includeNull,
              is_parent_synced: colCondition?.isParentSynced,
              is_user_visible: colCondition?.isUserVisible,
              ui_page_column_id: colCondition?.pageColumnId,
              option_value: colCondition.filterOptionValue,
              filter_db_type: colCondition.filterDbType
            });
          }
          if (colConditionInputs?.length) {
            await Promise.all([
              ...colConditionInputs.map((input) => updateRecordAsync({ tableName: "ui_column_condition", input }))
            ]);
          }
        }
        additionalProps.onSuccess?.();
      } else {
        // Add new filter entry
        const newFilterRuleInput = {
          filter_type: additionalProps.filterType,
          org_id: additionalProps.orgId
        };
        const { data: newColFilterData, error } = await addRecordAsync({
          tableName: "ui_filters",
          input: newFilterRuleInput
        });
        if (error) {
          toast.error("Error adding column filter!");
          return;
        }
        if (newColFilterData?.[0]?.id) {
          const newColFilterId = newColFilterData[0].id;
          const colConditionInputs = [];
          for (const filter of filters) {
            colConditionInputs.push({
              filter_lookup_path: filter.filterLookupPath,
              field: filter.filterField,
              operator: filter.filterOperator,
              value: filter.filterValue,
              column_filters_id: newColFilterId,
              record_id_source: filter.recordIdSource,
              include_null: filter.includeNull,
              is_parent_synced: filter?.isParentSynced,
              is_user_visible: filter?.isUserVisible,
              ui_page_column_id: filter?.pageColumnId,
              option_value: filter.filterOptionValue,
              filter_db_type: filter.filterDbType,
              org_id: additionalProps.orgId
            });
          }
          const finalPropName = getForeignKeyForFilterType(additionalProps.filterType);
          const finalTableName = getTableNameForFilters({
            viewColumnId: additionalProps.viewColumnId,
            pageColumnId: additionalProps.pageColumnId,
            tabId: additionalProps.tabId,
            pageId: additionalProps.pageId,
            sectionId: additionalProps.sectionId
          });
          const finalReqs = [
            ...colConditionInputs.map((input) => addRecordAsync({ tableName: "ui_column_condition", input }))
          ];

          if (finalTableName && finalPropName) {
            finalReqs.push(
              updateRecordAsync({
                tableName: finalTableName,
                input: {
                  id:
                    additionalProps.pageColumnId ||
                    additionalProps.tabId ||
                    additionalProps.pageId ||
                    additionalProps.sectionId ||
                    additionalProps.viewColumnId,
                  [finalPropName]: newColFilterId,
                  org_id: additionalProps.orgId
                }
              })
            );
          }

          await Promise.all(finalReqs);
          additionalProps.onSuccess?.();
          if (additionalProps.filterType) {
            return newColFilterId;
          }
        }
      }
    },
    [addRecordAsync, updateRecordAsync]
  );

  const handleDeleteFilterFK = useCallback(
    async ({ pageColumnId, tabId, filterType, onSuccess, pageId, sectionId, viewColumnId }: AdditionalProps) => {
      const finalPropName = getForeignKeyForFilterType(filterType);
      const finalTableName = getTableNameForFilters({
        pageColumnId,
        tabId,
        pageId,
        sectionId,
        viewColumnId
      });
      if (!finalPropName && !finalTableName) return;
      await updateRecordAsync({
        tableName: finalTableName,
        input: {
          id: pageColumnId || tabId || pageId || sectionId || viewColumnId,
          [finalPropName]: null
        }
      });
      onSuccess?.();
    },
    [updateRecordAsync]
  );

  const handleDeleteFilters = useCallback(
    async (filters: TableFilterType[], additionalProps: AdditionalProps) => {
      if (!filters.length) return;
      const colFilterRule = filters[0].originalRuleItem;
      if (colFilterRule) {
        await handleDeleteFilterFK(additionalProps);
        await deleteColumnRuleAndConditions(colFilterRule as ColumnRule, {
          ruleType: COLUMN_RULE_PARENT_TYPES.UI_COLUMN_FILTER
        });
      }
    },
    [deleteColumnRuleAndConditions, handleDeleteFilterFK]
  );

  const handleDeleteColumnCondition = useCallback(
    async (columnConditionId: string) => {
      if (!columnConditionId) return;
      await removeRecordAsync({
        tableName: "ui_column_condition",
        id: columnConditionId
      });
    },
    [removeRecordAsync]
  );

  return {
    handleFilterChange,
    handleDeleteFilters,
    handleDeleteColumnCondition
  };
};

export default useUpsertDeleteFilters;
